import Image from '../components/Image'

const Blog = () => (
  <div className='blog'>
    <div>Blog will go here</div>
    <Image src='avocado.gif' />
  </div>
)

export default Blog
