import Image from './components/Image'

const Home = () => (
  <div className='home'>
    <div>いらっしゃいませ!!</div>
    <Image src='piano-beluga.gif' />
  </div>
)

export default Home
